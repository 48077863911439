import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import { getInvitation, upsertInvitation } from "../functions/invitations";

const EventContext = createContext();

function EventInput({ index }) {
  const { events, setEvents } = useContext(EventContext);

  const [event] = useState(events.filter((item) => item.id === index)[0]);

  const deleteEvent = () => {
    setEvents((prev) => prev.filter((item) => item.id !== index));
  };

  const onEventChange = (e) => {
    const { name, value } = e.target;
    const fieldName = name.split("-")[2];

    setEvents((prev) => {
      return prev.map((item) =>
        item.id === index
          ? {
              ...item,
              [fieldName]: value,
            }
          : item
      );
    });
  };

  return (
    <div className="flex flex-col gap-6 mb-20 border-zinc-200 p-10 rounded-lg border-2">
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-lg font-medium text-gray-900 dark:text-white">
          Nama Acara
        </h1>
        <button
          onClick={deleteEvent}
          type="button"
          className="px-3 py-2 text-xs font-medium text-center text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
        >
          X
        </button>
      </div>
      <div>
        <label
          htmlFor={`${index}-event-name`}
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Nama Acara
        </label>
        <input
          type="text"
          id={`${index}-event-name`}
          name={`${index}-event-name`}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full md:w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Masukan nama acara"
          required
          autoComplete="off"
          value={event.name}
          onChange={onEventChange}
        />
      </div>
      <div className="flex flex-col gap-6 md:flex-row">
        <div>
          <label
            htmlFor={`${index}-event-date`}
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Tanggal
          </label>
          <input
            type="date"
            id={`${index}-event-date`}
            name={`${index}-event-date`}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            autoComplete="off"
            value={event.date}
            onChange={onEventChange}
          />
        </div>
        <div>
          <label
            htmlFor={`${index}-event-time`}
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Jam
          </label>
          <input
            type="time"
            id={`${index}-event-time`}
            name={`${index}-event-time`}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            autoComplete="off"
            value={event.time}
            onChange={onEventChange}
          />
        </div>
      </div>
      <div>
        <label
          htmlFor={`${index}-event-address`}
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Alamat
        </label>
        <textarea
          id={`${index}-event-address`}
          name={`${index}-event-address`}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Masukan alamat"
          required
          autoComplete="off"
          value={event.address}
          onChange={onEventChange}
        />
      </div>
    </div>
  );
}

export default function FormInvitation() {
  const [invitation, setInvitation] = useState({
    men_name: undefined,
    men_photo: undefined,
    woman_name: undefined,
    woman_photo: undefined,
  });
  const [isDisable, setIsDisable] = useState(true);
  const [events, setEvents] = useState([]);
  const [eventID, setEventID] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [photoURL, setPhotoURL] = useState({
    men_photo: null,
    woman_photo: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const data = await getInvitation();
      if (data) {
        setIsEdit(true);
        setEvents(data.events);
        setDocumentId(data.id);
        setInvitation({
          men_name: data.men_name,
          woman_name: data.woman_name,
          men_photo: data.men_photo,
          woman_photo: data.woman_photo,
        });
        setPhotoURL({
          men_photo: data.men_photo,
          woman_photo: data.woman_photo,
        });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let disable = false;

    if (!invitation.men_name) disable = true;
    if (!invitation.men_photo_temp && !isEdit) disable = true;
    if (!invitation.woman_name) disable = true;
    if (!invitation.woman_photo_temp && !isEdit) disable = true;

    setIsDisable(disable);
  }, [invitation]);

  const onInvitationChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      setPhotoURL((prev) => {
        return {
          ...prev,
          [name]: URL.createObjectURL(e.target.files[0]),
        };
      });
      setInvitation((prev) => {
        return {
          ...prev,
          [`${name}_file`]: e.target.files[0],
          [`${name}_temp`]: value,
        };
      });
    } else {
      setInvitation((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const addEvent = () => {
    const newEvent = {
      id: eventID,
      name: undefined,
      date: undefined,
      time: undefined,
      address: undefined,
    };

    setEvents((prev) => {
      return [...prev, newEvent];
    });

    setEventID((prev) => prev + 1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    invitation.events = events;
    try {
      await upsertInvitation(invitation, isEdit, documentId);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <EventContext.Provider value={{ events, setEvents }}>
      <MainLayout pageName={"Edit Undangan"}>
        <div className="bg-white p-5 rounded-md flex items-center justify-between">
          <div className="my-10 w-3/4 mx-auto flex flex-col gap-6">
            <div>
              <label
                htmlFor="men_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Nama pengantin pria
              </label>
              <input
                type="text"
                id="men_name"
                name="men_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Masukan nama pengantin pria"
                onChange={onInvitationChange}
                required
                autoComplete="off"
                value={invitation.men_name}
              />
            </div>

            <div>
              <label
                htmlFor="men_photo"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Foto pengantin pria
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="men_photo"
                type="file"
                name="men_photo"
                onChange={onInvitationChange}
                value={invitation.men_photo_temp}
              />
            </div>

            <div>
              <img src={photoURL.men_photo} className="h-44" />
            </div>

            <div>
              <label
                htmlFor="woman_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Nama pengantin wanita
              </label>
              <input
                type="text"
                id="woman_name"
                name="woman_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Masukan nama pengantin wanita"
                required
                autoComplete="off"
                onChange={onInvitationChange}
                value={invitation.woman_name}
              />
            </div>

            <div>
              <label
                htmlFor="woman_photo"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Foto pengantin wanita
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="woman_photo"
                type="file"
                name="woman_photo"
                onChange={onInvitationChange}
                value={invitation.woman_photo_temp}
              />
            </div>

            <div>
              <img src={photoURL.woman_photo} className="h-44" />
            </div>

            <h1 className="text-zinc-900 text-xl font-semibold mt-10">Acara</h1>
            {events.map((event) => (
              <EventInput key={event.id} index={event.id} />
            ))}
            <button
              type="button"
              onClick={addEvent}
              className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800 md:w-1/4 w-full"
            >
              Tambah acara
            </button>

            <button
              type="submit"
              disabled={isDisable}
              className={
                isDisable
                  ? "text-white mt-10 bg-slate-700 cursor-not-allowed font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600"
                  : "text-white mt-10 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              }
              onClick={onSubmit}
            >
              Simpan
            </button>
          </div>
        </div>
      </MainLayout>
    </EventContext.Provider>
  );
}
