import React from 'react';
import { IoFilter } from 'react-icons/io5';

export default function Table({ columns = [], data }) {
  const TableHeader = ({ sort = false, columnName }) => {
    if (sort) {
      return (
        <th scope="col" className="py-3 px-6">
          <div className="flex items-center">
            {columnName}
            <IoFilter />
          </div>
        </th>
      );
    } else {
      return (
        <th scope="col" className="py-3 px-6">
          {columnName}
        </th>
      );
    }
  };

  const TableRowItem = ({ columns = [], row }) => {
    return (
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        {columns.map((column, index) => {
          return <td key={index}>{column.render(row[column.id])}</td>;
        })}
      </tr>
    );
  };

  return (
    <div className="overflow-x-auto relative sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {columns.map((column, index) => (
              <TableHeader key={index} columnName={column.name} />
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <TableRowItem key={index} columns={columns} row={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
