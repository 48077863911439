import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../configs/firebase";
import { deleteFile, handleUpload } from "./storage";

const collectionName = "invitations";

export async function upsertInvitation(data, isEdit, documentId) {
  try {
    const user = JSON.parse(localStorage.getItem("user"));

    if (data.men_photo_file) {
      if (isEdit) await deleteFile(data.men_photo);
      const downloadURLMen = await handleUpload(data.men_photo_file);
      data.men_photo = downloadURLMen;
    }

    if (data.woman_photo_file) {
      if (isEdit) await deleteFile(data.woman_photo);
      const downloadURLWoman = await handleUpload(data.woman_photo_file);
      data.woman_photo = downloadURLWoman;
    }

    if (isEdit) {
      await updateDoc(doc(db, collectionName, documentId), {
        events: data.events,
        men_name: data.men_name,
        men_photo: data.men_photo,
        woman_name: data.woman_name,
        woman_photo: data.woman_photo,
        user_id: user.uid,
      });
    } else {
      await addDoc(collection(db, collectionName), {
        events: data.events,
        men_name: data.men_name,
        men_photo: data.men_photo,
        woman_name: data.woman_name,
        woman_photo: data.woman_photo,
        user_id: user.uid,
      });
    }
  } catch (error) {
    return new Error(error);
  }
}

export async function getInvitation() {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const q = query(
      collection(db, collectionName),
      where("user_id", "==", `${user.uid}`)
    );
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))[0];
    return data;
  } catch (error) {
    return new Error(error);
  }
}
