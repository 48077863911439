// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAioKXfxxO1TwzwDe5aDGsOzdpRZPstgEQ',
  authDomain: 'wedding-9e676.firebaseapp.com',
  databaseURL:
    'https://wedding-9e676-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'wedding-9e676',
  storageBucket: 'wedding-9e676.appspot.com',
  messagingSenderId: '691031851297',
  appId: '1:691031851297:web:39ba77c62be208c499be60',
  measurementId: 'G-XT8GEXYGEK',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
