import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { auth, db } from '../configs/firebase';

export async function register({ name, email, password }) {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    localStorage.setItem('user', JSON.stringify(user));
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

export async function login({ email, password }) {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const user = res.user;
    localStorage.setItem('user', JSON.stringify(user));
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

export async function logout() {
  await signOut(auth);
  localStorage.removeItem('user');
}
