import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import FormGuest from './pages/FormGuest';
import FormInvitation from './pages/FormInvitation';
import Guest from './pages/Guests';
import Invitation from './pages/Invitation';
import Login from './pages/Login';
import Register from './pages/Register';

function ProtectedRoute({ children }) {
  const user = localStorage.getItem('user');

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
}

function App() {
  return (
    <div className="light">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Navigate to="/invitation" />} />
            <Route
              path="/invitation"
              element={
                <ProtectedRoute>
                  <Invitation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/invitation/form"
              element={
                <ProtectedRoute>
                  <FormInvitation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/guests"
              element={
                <ProtectedRoute>
                  <Guest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/guests/create"
              element={
                <ProtectedRoute>
                  <FormGuest />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
