import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import Table from '../components/Table';
import axios from '../configs/axios/index';
import { getGuests } from '../functions/guestsApi';

export default function Guest() {
  const [offset, setOffset] = useState(null);
  const [guests, setGuests] = useState([]);

  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      const data = await getGuests();
      setGuests(data);
    }
    fetchData();
  }, []);

  const columns = [
    {
      name: 'Nama',
      id: 'name',
      render: (value) => (
        <p className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {value}
        </p>
      ),
    },
    {
      name: 'Telepon',
      id: 'phone_number',
      render: (value) => (
        <p className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {value}
        </p>
      ),
    },
    {
      name: 'Ubah',
      id: 'edit',
      render: (value) => (
        <a
          href={value}
          className="font-medium py-4 px-6 whitespace-nowrap text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
        >
          Ubah
        </a>
      ),
    },
    {
      name: 'Kirim WA',
      id: 'send_wa',
      render: (value) => (
        <a
          href={value}
          className="py-4 px-6 font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
        >
          Kirim
        </a>
      ),
    },
  ];

  return (
    <MainLayout pageName={'Tamu'}>
      <div className="bg-white p-5 md:p-10 rounded-md">
        <div className="flex justify-between mb-8">
          <h1 className="text-zinc-900 text-xl font-semibold mb-10">
            Daftar tamu
          </h1>
          <button
            type="button"
            onClick={() => navigate("/guests/create")}
            className="h-12 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Tambah tamu
          </button>
        </div>
        <Table columns={columns} data={guests} />
      </div>
    </MainLayout>
  );
}
