import React, { useState } from 'react';
import { IoMenu } from 'react-icons/io5';
import { Transition } from '@headlessui/react';
import { logout } from '../functions/auth';
import { useNavigate } from 'react-router-dom';

function NavItem({ link, name }) {
  return (
    <a
      href={link}
      className=" hover:text-gray-500 text-zinc-900 px-3 py-2 rounded-md text-sm font-medium"
    >
      {name}
    </a>
  );
}

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const onLogout = async () => {
    await logout();
    navigate('/login');
  };

  const menus = [
    {
      link: '/invitation',
      name: 'Undangan',
    },
    {
      link: '/guests',
      name: 'Tamu',
    },
  ];

  return (
    <nav className="sticky top-0 z-50 bg-white backdrop-filter backdrop-blur-lg bg-opacity-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                className="h-8 w-8"
                src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                alt="Workflow"
              />
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {menus.map((menu, index) => (
                  <NavItem key={index} link={menu.link} name={menu.name} />
                ))}
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={onLogout}
            className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Keluar
          </button>
          <div className="-mr-2 flex md:hidden">
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-zinc-900 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <IoMenu />
            </button>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {() => (
            <div className="md:hidden" id="mobile-menu">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {menus.map((menu, index) => (
                  <NavItem key={index} link={menu.link} name={menu.name} />
                ))}
              </div>
            </div>
          )}
        </Transition>
      </div>
    </nav>
  );
}
