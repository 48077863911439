import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { storage } from "../configs/firebase";

export async function handleUpload(file) {
  const extension = file.type.replace(/(.*)\//g, "");
  const fileName = `${Date.now()}.${extension}`;

  const storageRef = ref(storage, `/files/${fileName}`);
  const snapshot = await uploadBytes(storageRef, file);
  return await getDownloadURL(snapshot.ref);
}

export async function deleteFile(url) {
  const storageRef = ref(storage, getPathStorageFromUrl(url));
  return await deleteObject(storageRef);
}

function getPathStorageFromUrl(url) {
  const baseUrl =
    "https://firebasestorage.googleapis.com/v0/b/wedding-9e676.appspot.com/o/";
  let imagePath = url.replace(baseUrl, "");
  const indexOfEndPath = imagePath.indexOf("?");
  imagePath = imagePath.substring(0, indexOfEndPath);
  imagePath = imagePath.replace("%2F", "/");
  return imagePath;
}
