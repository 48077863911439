import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../functions/auth';

export default function Login() {
  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const [isDisable, setIsDisable] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    let disable = false;

    if (!form.email) disable = true;
    if (!form.password) disable = true;

    setIsDisable(disable);
  }, [form]);

  const changeForm = ({ name, value }) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(form);
      navigate('/');
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFocus = (event) => {
    event.target.setAttribute('autocomplete', 'off');
  };

  return (
    <main className="bg-slate-100 min-h-screen flex justify-center">
      <div className="bg-white p-20 md:w-1/2 my-36">
        <h1 className="text-3xl font-semibold text-gray-900 text-center">
          Masuk
        </h1>
        <form
          className="mt-10 w-3/4 mx-auto flex flex-col gap-6"
          method="post"
          onSubmit={onSubmit}
        >
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={form.email}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Masukan alamat email"
              required
              onChange={(e) => changeForm(e.target)}
              autoComplete="off"
              onFocus={onFocus}
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={form.password}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Buat password"
              required
              onChange={(e) => changeForm(e.target)}
            />
          </div>
          <button
            type="submit"
            disabled={isDisable}
            className={
              isDisable
                ? 'text-white bg-slate-700 cursor-not-allowed font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600'
                : 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            }
          >
            Masuk
          </button>
        </form>
      </div>
    </main>
  );
}
