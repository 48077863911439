import axios from 'axios';
import errorResponseHandler from './errorResponseHandler';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_AIRTABLE_URL}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
  },
});

instance.interceptors.response.use(
  (response) => response.data,
  errorResponseHandler
);

export default instance;
