import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import { getInvitation } from "../functions/invitations";

export default function Invitation() {
  const [invitation, setInvitation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const data = await getInvitation();
      setInvitation(data);
    }
    fetchData();
  }, []);

  return (
    <MainLayout pageName={"Undangan"}>
      <div className="bg-white p-5  rounded-md md:mx-20 flex items-center justify-between">
        <h1 className="text-xl font-medium text-center">Data Undangan</h1>
        <div>
          <button
            type="button"
            onClick={() => navigate("/invitation/form")}
            className="h-12 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Edit Undangan
          </button>
          <button
            type="button"
            onClick={() => navigate("/invitation/form")}
            className="h-12 text-zinc-900 bg-blue-300 hover:bg-blue-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Preview
          </button>
        </div>
      </div>

      <div className="bg-white p-5 md:p-10 rounded-md md:mx-20 mt-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div>
            <h1 className="text-xl font-medium text-center">Pengantin Pria</h1>
            <img
              src={invitation?.men_photo}
              className="w-1/2 mt-5 mx-auto"
              alt="men"
            />
            <h1 className="text-2xl mt-4 text-center">
              {invitation?.men_name}
            </h1>
          </div>
          <div>
            <h1 className="text-xl font-medium text-center">
              Pengantin Wanita
            </h1>
            <img
              src={invitation?.woman_photo}
              className="w-1/2 mt-5 mx-auto"
              alt="woman"
            />
            <h1 className="text-2xl mt-4 text-center">
              {invitation?.woman_name}
            </h1>
          </div>
        </div>
      </div>
      <div className="bg-white p-5 md:p-10 rounded-md md:mx-20 mt-10">
        <h1 className="text-zinc-900 text-xl font-semibold mb-10">Acara</h1>

        {invitation?.events?.map((event) => {
          return (
            <div className="mt-10" key={event?.id}>
              <h1 className="text-xl font-medium">{event?.name}</h1>
              <p className="text-md mt-2 font-medium">
                {Intl.DateTimeFormat("id", {
                  weekday: "long",
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }).format(new Date(event?.date))}
              </p>
              <p className="text-md font-light">{event?.time}</p>
              <p className="mt-2 text-md">{event?.address}</p>
            </div>
          );
        })}
      </div>
    </MainLayout>
  );
}
