import React from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';

export default function MainLayout({ pageName, children }) {
  return (
    <div>
      <Navbar />
      <Header pageName={pageName} />
      <main className="bg-slate-100 min-h-screen">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
}
