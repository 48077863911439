import { addDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../configs/firebase';

const collectionName = 'guests';

export async function getGuests() {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const data = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return data;
  } catch (error) {
    return new Error(error);
  }
}

export async function storeGuest(data) {
  try {
    await addDoc(collection(db, collectionName), {
      guest: data,
    });
  } catch (error) {
    return new Error(error);
  }
}
